import { Box, Button, Flex, Input } from '@audentio/stuff/src';
import { Form } from '@audentio/stuff/src/Form';
import { cookies } from '@audentio/utils/src/cookies';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import React, { useState } from 'react';
import { Paragraph } from '../Paragraph';
import { useTheme } from '../ThemeProvider';

export const ACEForm = ({ disabled = true }) => {
    const theme = useTheme();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    // the message displayed when the form is submitted
    const displayedMessage =
        'Thanks for your subscription! You will be sent an email shortly.';

    return (
        <Box>
            <Form
                onSubmit={(e, { getFormValue }) => {
                    e.preventDefault();
                    const { EMAIL } = getFormValue();

                    // update hubspot newsletter data if set on register
                    const portalId = '4911065';
                    const formGuid = 'ffc1b63c-13c5-4bd0-80d7-ec91c2b81aa6';

                    // set from the HubSpot JavaScript Tracking Code;
                    const hutk =
                        cookies &&
                        cookies.getItem &&
                        cookies.getItem('hubspotutk');

                    // always call this form if accepted TOS
                    if (!disabled) {
                        setError(null);
                        setLoading(true);

                        fetch(
                            `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Accept: 'application/json',
                                },
                                body: JSON.stringify({
                                    portalId,
                                    formGuid,
                                    fields: [
                                        {
                                            objectTypeId: '0-1',
                                            name: 'email',
                                            value: EMAIL,
                                        },
                                        {
                                            objectTypeId: '0-1',
                                            name: 'customer_of_',
                                            value:
                                                'Newsletter subscriber from ACE for XenForo',
                                        },
                                    ],
                                    legalConsentOptions: {
                                        consent: {
                                            consentToProcess: true,
                                            text:
                                                'Form submit as consent to process',
                                            communications: [
                                                {
                                                    value: true,
                                                    subscriptionTypeId: 34237268,
                                                    text:
                                                        'Subscribe to our newsletter to get the discount code',
                                                },
                                            ],
                                        },
                                    },
                                    context: {
                                        hutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                                        pageUri: window.location.href,
                                    },
                                }),
                            }
                        )
                            .then(response => {
                                setLoading(false);
                                setSuccess(true);
                                return response.json();
                            })
                            .catch(e => {
                                setLoading(false);
                                setError('An error has occurred');
                            });
                    }
                }}
            >
                <Input
                    type="email"
                    placeholder="Email Address"
                    bg="primaryBackground"
                    name="EMAIL"
                    px={0}
                    border={0}
                    borderRadius={0}
                    borderBottom="1px solid #d6dfe4"
                    isRequired
                />
                {(error || success) && !loading && (
                    <Flex pt="spacing">
                        <Box>
                            {error ? (
                                <AlertCircleIcon
                                    size={20}
                                    color={theme.colors.warning[400]}
                                />
                            ) : (
                                <CheckCircleIcon
                                    size={20}
                                    color={theme.colors.success[500]}
                                />
                            )}
                        </Box>
                        <Paragraph
                            pl="spacing-sm"
                            color="boldText"
                            fontSize="smallBody"
                        >
                            {displayedMessage && displayedMessage}
                        </Paragraph>
                    </Flex>
                )}
                <Flex
                    justifyContent="flex-end"
                    pt="spacing"
                    pb={error || success ? 'spacing' : 'spacing-lg'}
                >
                    <Box>
                        <Button type="submit" isDisabled={disabled}>
                            Subscribe
                        </Button>
                    </Box>
                </Flex>
            </Form>
        </Box>
    );
};
