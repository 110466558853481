import {
    Box,
    Checkbox,
    CheckboxGroup,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@audentio/stuff/src';
import { Text } from '@audentio/stuff/src/Text';
import CloseIcon from 'mdi-react/CloseIcon';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Anchor } from '../Anchor';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { ThemeProvider } from '../ThemeProvider';
import { ACEForm } from './ACEForm';

export const DownloadModal = ({ isOpen, setIsOpen }: any) => {
    const close = () => {
        setIsOpen(false);
        setSubCheck(true);
    };
    const firstField = useRef();
    const [subCheck, setSubCheck] = useState(true);

    return (
        <ThemeProvider defaultMode="light">
            <Helmet>
                <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js.hs-scripts.com/4911065.js"
                />
            </Helmet>
            <Modal initialFocusRef={firstField} isOpen={isOpen} onClose={close}>
                <ModalOverlay />
                <ModalContent maxW="5xl">
                    <ModalHeader bg="tertiaryBackground">
                        <Heading
                            kind="h5"
                            py="spacing-sm"
                            color="primaryBackground"
                        >
                            Subscribe to our newsletter
                        </Heading>
                        <Box cursor="pointer" onClick={close}>
                            <CloseIcon color="white" />
                        </Box>
                    </ModalHeader>

                    <ModalBody bg="primaryBackground" py={0}>
                        <Box pt="spacing-lg" pb="spacing">
                            <Heading kind="h5">
                                Get a code to download ACE for XenForo free for
                                a limited time in beta.
                            </Heading>
                        </Box>
                        <Box>
                            <Text>
                                Before you can officially download ACE for
                                XenForo, please read through our terms carefully
                                and accept that you understand them.
                            </Text>
                        </Box>
                        <CheckboxGroup
                            onChange={e =>
                                e.length === 4
                                    ? setSubCheck(false)
                                    : setSubCheck(true)
                            }
                        >
                            <Checkbox size="lg" pt="spacing-lg">
                                ACE for XenForo is in beta and will have no
                                support. If you are in need of support, you can
                                get that through our{' '}
                                <Anchor
                                    external
                                    textDecoration="underline"
                                    href="https://community.audent.io/methods/resources"
                                >
                                    Resources
                                </Anchor>{' '}
                                subscription.
                            </Checkbox>
                            <Checkbox size="lg" pt="spacing">
                                We recommend testing first and backing up your
                                install before moving to a production
                                environment. Emergency support is not guaranteed
                                if it is put on production.
                            </Checkbox>
                            <Checkbox size="lg" pt="spacing">
                                AC.UI heavily edits some add-ons (including
                                Feeds and XFMG) so there may be compatibility
                                issues between versions.
                            </Checkbox>

                            <Checkbox size="lg" pt="spacing">
                                We would appreciate knowing about any bugs, so
                                please share them{' '}
                                <Anchor
                                    external
                                    textDecoration="underline"
                                    href="https://forms.clickup.com/f/83y99-3360/J9WYQCZYD6DV2P0GG1"
                                >
                                    here
                                </Anchor>
                                .
                            </Checkbox>
                        </CheckboxGroup>

                        <Box py="spacing">
                            <Heading kind="h6" color="boldText">
                                Subscribe to our newsletter to get the discount
                                code
                            </Heading>
                            <Paragraph fontSize="smallBody" color="bodyText">
                                After fully verifying your subscription, you
                                will be sent an email with the coupon code
                                within a few minutes.
                            </Paragraph>
                        </Box>
                        <ACEForm disabled={subCheck} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </ThemeProvider>
    );
};
