import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import React, { useState } from 'react';
import { Box } from 'src/components/Box';
import { ButtonGroup } from 'src/components/ButtonGroup';
import { CheckListItemGroup } from 'src/components/CheckListItemGroup';
import { DownloadModal } from 'src/components/DownloadModal';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { Paragraph } from 'src/components/Paragraph';
import { ThemeProvider } from 'src/components/ThemeProvider';

export default function AceForXenforo({ data }) {
    const [isOpen, setIsOpen] = useState(false);

    const onDownloadClick = () => {
        setIsOpen(true);

        // TRACK GA EVENT
        trackCustomEvent({
            // required - The object that was interacted with (e.g.video)
            category: 'ACE for XF',
            // required - action - Type of interaction (e.g. 'play')
            action: 'Button Clicked',
            // optional - label - page of the submission - Useful for categorizing events
            label: 'Download NOw',
        });
    };

    return (
        <Layout
            pageTitleFull="Our best XenForo experience free for a limited time"
            siteDescription="Our modern user experience for the XenForo forum platform with an intentional design and powerful feeds."
            wrapChildren
        >
            <MethodsLandingHero
                title="Our best XenForo experience free for a limited time"
                video="/videos/methods/resources/engagement.mp4"
                videoPoster="/videos/targeted/ace-for-xenforo/ace-video-poster.jpg"
                videoId="ace-for-xenforo-hero-video"
                subtitle="ACE for XenForo is our answer to what is the most ideal interface and experience for a community. AC.UI, a theme centered around a simple and performant design, and Feeds, an add-on that allows filtering of dedicated feeds."
                sectionProps={{
                    vertical: 'md',
                    pb: ['40px', 'spacing-xl', null, null, null],
                    mb: 0,
                }}
                buttons={[
                    {
                        onClick: onDownloadClick,
                        text: 'download now',
                        fill: 'white',
                    },
                    {
                        text: 'watch video',
                        href: 'https://youtu.be/zU2FxgpVNes',
                        fill: 'white',
                        external: true,
                    },
                ]}
                buttonsProps={{
                    paddingTop: 6,
                }}
            />
            <MediaSection
                video="/videos/targeted/ace-for-xenforo/principles.mp4"
                videoId="ace-for-xenforo-principles-video"
                title="We're changing the forum experience"
                bg="inherit"
            >
                <Box pt="spacing">
                    <Paragraph pt="spacing">
                        UI/UX on forum platforms has been outdated for too long.
                        We recognize the significance of small community
                        discussion and want to make forums as successful as we
                        can.
                    </Paragraph>
                    <Paragraph pt="spacing">
                        Our team is leading the charge in creating the new
                        standard for forum platforms because we have the
                        passion, desire, skills, and knowledge to do it.
                    </Paragraph>
                </Box>
            </MediaSection>

            <MediaSection
                videoId="ace-for-xenforo-simple-video"
                video="/videos/targeted/ace-for-xenforo/acui2.mp4"
                title="Meticulously designed for simplicity"
            >
                <CheckListItemGroup
                    items={[
                        {
                            title: 'app-like navigation for mobile',
                            description:
                                'We completely reworked how navigation is handled on mobile by having bottom navigation tabs to mimic what users come to expect from an app experience.',
                        },
                        {
                            title: 'alerts simplified',
                            description:
                                'In one convenient user menu, users can now view their alerts, conversations, settings, and bookmarks in one single user menu that removes extra clicks and simplifies navigation.',
                        },
                        {
                            title: 'thoughtfully designed',
                            description:
                                "Every detail was considered, from font sizes to contrast and legibility, to ease of navigation. We made the micro-decisions about the ideal interface so you don't have to.",
                        },
                    ]}
                />
                <ButtonGroup
                    buttons={[
                        {
                            text: 'download now',
                            onClick: onDownloadClick,
                        },
                        {
                            text: 'DOCUMENTATION',
                            href:
                                'https://help.themehouse.com/en/articles/5445663-getting-started-with-ace-x',
                        },
                    ]}
                />
            </MediaSection>
            <ThemeProvider defaultMode="dark">
                <MediaSection
                    flipped
                    video="/videos/targeted/ace-for-xenforo/feeds.mp4"
                    videoId="ace-for-xenforo-feeds-video"
                    title="Deliver dedicated feeds with powerful filtering"
                    bg="primaryBackground"
                >
                    <CheckListItemGroup
                        items={[
                            {
                                title: 'intentional and interactive feeds',
                                description:
                                    'We deliberately created various, customizable feeds for different types of users that allow for engagement right in the feed.',
                            },
                            {
                                title: 'universal create button',
                                description:
                                    'We standardized common forum actions such as creating a thread into one single call-to-action.',
                            },
                            {
                                title: 'filtering',
                                description:
                                    'Users can filter by forum, thread type, watched content, people you follow, and more to discover the content they want to see.',
                            },
                            {
                                title: 'promote to a curated hot feed',
                                description:
                                    'Community staff can hand-select content from a recommended list or let our algorithm do the work for them.',
                            },
                        ]}
                    />
                    <ButtonGroup
                        buttons={[
                            {
                                text: 'download now',
                                onClick: onDownloadClick,
                                fill: 'white',
                            },
                            {
                                text: 'DOCUMENTATION',
                                fill: 'white',
                                href:
                                    'https://help.themehouse.com/en/articles/5445663-getting-started-with-ace-x',
                            },
                        ]}
                    />
                </MediaSection>
            </ThemeProvider>
            <MediaSection
                title="Get ACE for XenForo along with our select add-ons for the best strategy"
                videoId="ace-for-xenforo-resources-video"
                videoPoster="/videos/methods/resources/hero-poster.png"
                video="/videos/methods/resources/hero.mp4"
            >
                <Paragraph py="spacing">
                    The same community tools we implement for our clients to
                    support the best strategies of improving user onboarding,
                    engagement, experience, and more. For $1,000 annually, have
                    access to both our Audentio Community Select and ThemeHouse
                    products with support.
                </Paragraph>
                <ButtonGroup
                    buttons={[
                        {
                            text: 'see resource plan',
                            href:
                                'https://community.audent.io/methods/resources',
                        },
                    ]}
                />
            </MediaSection>
            <LandingFeatureGrid
                center
                offset={false}
                flexDirection="column"
                title="Learn more about ACE for XenForo"
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                    my: { _: 'spacing-lg', md: 0 },
                }}
                showContact={false}
                items={[
                    {
                        image: data.forumTheme.childImageSharp.fluid,
                        heading:
                            'AC.UI, our new forum theme and what we did with it',
                        content:
                            "We've strived to be thought leaders in the community space, product designers bringing complex but useful products to the XenForo market, and UI/UX designers for what a forum interface...",
                        href:
                            'https://adjourn.audent.io/ac-ui-our-new-core-forum-theme-for-xenforo-and-why-it-is-a-better-experience-8b4c9996506d',
                    },
                    {
                        image: data.mediaComparison.childImageSharp.fluid,
                        heading:
                            'How we improved the forum user experience by learning from social media',
                        content:
                            'Online communities, specifically forums, are struggling to keep up with the modern user experience that large social media platforms...',
                        href:
                            'https://adjourn.audent.io/how-we-can-improve-the-forum-user-experience-41e4e036a27f',
                    },
                    {
                        image: data.forumUx.childImageSharp.fluid,
                        heading:
                            'Through the Ages: Content Discoverability UX on Forums',
                        content:
                            'A brief history of UX improvements focused on modernizing and improving classic forum discoverability techniques that XenForo software and others have been using for decades.',
                        href:
                            'https://adjourn.audent.io/through-the-ages-content-discoverability-ux-on-forums-c50a06a8784a',
                    },
                ]}
            />
            <DownloadModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </Layout>
    );
}

export const query = graphql`
    query aceFoXenforoImages {
        forumTheme: file(
            relativePath: { eq: "images/ace-for-xenforo/forumTheme.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        optimizing: file(
            relativePath: { eq: "images/ace-for-xenforo/optimizing.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        mediaComparison: file(
            relativePath: { eq: "images/ace-for-xenforo/mediaComparison.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        forumUx: file(
            relativePath: { eq: "images/ace-for-xenforo/forumUx.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aceForXenForo: file(
            relativePath: { eq: "images/ace-for-xenforo/aceForXenForo.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
